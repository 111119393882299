<template>
    <div class="content-consult-pqrs">
        <div class="header-page">
            <div class="container-breadcrumb">
                <router-link class="text-breadcrumb" :to="{name: 'Home'}">Inicio</router-link>
                <span class="text-breadcrumb">Consultar PQRS por cuotas</span>
            </div>
            <h1>
                CONSULTAR PQRS POR CUOTAS
            </h1>
        </div>
        <div class="container">
            <h3 class="text-center mb-4">¡Consulta el estado de tu PQRS!</h3>
            <p class="mb-5">
                Aquí podras consultar el estado de tu PQRS, recuerda que debes tener el número de radicado previamente generado,
                si aún no cuentas con uno puedes solicitarlo <router-link :to="{name: 'pqrsquac'}">Aquí</router-link>.
            </p>
            <form @submit.prevent="evalFormPQR" class="form-send-pqr">
                <div class="col-12 col-md-6 pe-md-2 position-relative">
                    <input
                        class="input-quac w-100 mb-4"
                        type="text"
                        @blur="validateBlur()"
                        @focus="errorNRadicado = false"
                        placeholder="número de radicado"
                        v-model="nRadicado"
                    >
                    <span v-if="errorNRadicado" class="error-inputs">Este campo es obligatorio</span>
                    <span class="label-input" v-if="nRadicado">número de radicado</span>
                </div>
                <div class="col-12 col-md-6 ps-md-2 position-relative">
                    <button
                        class="buton w-100 buton-primary mb-4 mx-auto px-5"
                        :disabled="loading"
                        type="submit"
                    >
                        {{ loading ? 'Cargando...' : 'Enviar' }}
                    </button>
                </div>
            </form>
            <div class="container-table" v-if="infoSolicitudPQRS">
                <table class="table-summary">
                    <thead>
                        <tr>
                            <th colspan="2">Resumen PQRS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>MOTIVO</td>
                            <td>{{ infoSolicitudPQRS.motivo }}</td>
                        </tr>
                        <tr>
                            <td>DEPENDENCIA</td>
                            <td>{{ infoSolicitudPQRS.dependencia }}</td>
                        </tr>
                        <tr>
                            <td>FECHA SOLICITUD</td>
                            <td>{{ infoSolicitudPQRS.fecha_creacion }}</td>
                        </tr>
                        <tr>
                            <td>NOMBRE SOLICITANTE</td>
                            <td>{{ infoSolicitudPQRS.nombres }}</td>
                        </tr>
                        <tr>
                            <td>APELLIDOS SOLICITANTE</td>
                            <td>{{ infoSolicitudPQRS.apellidos }}</td>
                        </tr>
                        <tr>
                            <td>CORREO SOLICITANTE</td>
                            <td>{{ infoSolicitudPQRS.correo_electronico }}</td>
                        </tr>
                        <tr>
                            <td>DIRECCIÓN SOLICITANTE</td>
                            <td>{{ infoSolicitudPQRS.direccion }}</td>
                        </tr>
                        <tr>
                            <td>CIUDAD</td>
                            <td>{{ infoSolicitudPQRS.ciudad }}</td>
                        </tr>
                        <tr>
                            <td>CELULAR</td>
                            <td>{{ infoSolicitudPQRS.celular }}</td>
                        </tr>
                        <tr>
                            <td>DESCRIPCIÓN SOLICITUD</td>
                            <td>{{ infoSolicitudPQRS.mensaje }}</td>
                        </tr>
                        <tr>
                            <td>ARCHIVOS ADJUNTOS</td>
                            <td>{{ infoSolicitudPQRS.archivos.length }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            nRadicado: '',
            errorNRadicado: false,
            loading: false,
        }
    },
    computed: {
        ...mapState('pqrQuac', ['infoSolicitudPQRS']),
    },
    methods: {
        ...mapActions('pqrQuac', ['getInfoPRQS']),
        async evalFormPQR() {
            this.validateBlur()
            if (!this.errorNRadicado) {
                this.loading = true
                await this.getInfoPRQS({ 'nRadicado': this.nRadicado })
                this.loading = false
            }
        },
        validateBlur() {
            this.errorNRadicado = !(this.nRadicado.length > 1)
        }
    },
}
</script>
<style lang="scss">
.content-consult-pqrs {
    .container {
        width: 80%;
        max-width: 1400px;
        margin: 36px auto;
        position: relative;
        z-index: 1;
        @media (max-width: 768px) {
            width: 90%;
        }
    }
    .form-send-pqr {
        margin-bottom: 3rem;
    }
    .container-table {
        overflow: auto;
        padding: 0;
        margin-bottom: 5rem;
        .table-summary {
            width: 100%;
            min-width: 400px;
            thead {
                tr {
                    th {
                        background-color: var(--primaryColor);
                        color: white;
                        padding: .7rem 1rem;
                        text-align: center;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                    }
                }
            }
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        background-color: rgb(235, 235, 235);
                    }
                    td {
                        padding: .4rem 1rem;
                        &:first-child {
                            width: 50%;
                            min-width: 230px;
                            border-right: 2px solid rgb(179, 179, 179);
                        }
                    }
                }
            }
        }
    }
}
</style>